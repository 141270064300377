<template>
  <!--食品生鲜-->
  <div class="fresh_box">
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div slot="scrollList">
        <van-sticky>
          <div id="boxFixed" class="stickyBox">
            <div class="headdiv">
              <Head />
            </div>
            <fresh-nav :hover="freshNav" />
          </div>
        </van-sticky>

        <!--banner-->
        <fresh-banner :swiper="Banner" />
        <!--grid list-->
        <fresh-grid :category-grip="category" :hover="freshNav" />
        <!--常买清单-->
        <!-- <fresh-buy></fresh-buy> -->
        <!--滑动商品-->

        <fresh-scroll :activity-list="activity" />
        <!--推荐商品-->
        <!-- <fresh-list :list="commendList" id="dataList"></fresh-list> -->
        <Waterfalls :col="2" :list="waterData">
          <template slot-scope="{ data }">
            <div class="recommended_item">
              <imgLoading :src="data.goodCover" />
              <p class="tit">{{ data.goodName }}</p>
              <p class="desc">
                优选材质，便携使用便携优选材质，便携使用便携
              </p>
              <p class="money">
                <em>¥</em>
                {{ data.goodVipPrice }}
                <span>
                  <em>原价:¥</em>
                  {{ data.goodPrice }}
                </span>
              </p>
            </div>
          </template>
        </Waterfalls>

        <!--底部tab-->
      </div>
    </mescroll>
    <Tabbar />
  </div>
</template>
<script>

import imgLoading from '@/component/imgLoading'

import Waterfalls from '@/component/Waterfalls/index'

import mescroll from '@/component/fresh_scroll/mescroll'

import FreshScroll from '@/component/fresh_scroll'
import FreshBanner from '@/component/fresh_scroll/banner'
import FreshGrid from '@/component/fresh_scroll/grid'
import FreshNav from '@/component/fresh_scroll/nav'
import Head from '@/component/head/head'
import Tabbar from '@/component/Tabbar'
import Vue from 'vue'
import { getNav, getrecommendList } from '@/services/fresh'

import { Sticky } from 'vant'

Vue.use(Sticky)
export default {
  name: 'Fresh',
  components: {
    FreshNav,
    FreshBanner,
    FreshGrid,
    FreshScroll,
    Tabbar,
    Head,
    mescroll,
    Waterfalls, imgLoading
  },
  data() {
    return {
      mescrollDown: {
        use: true
      },
      mescrollUp: {
        // onScroll:this.handScroll,
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      },
      uid: '',
      Banner: '',
      category: '',
      activity: '',
      page: 1,
      pageSize: 10,
      commendList: [],
      waterData: [],
      class: '',
      freshNav: 'fresh'
    }
  },
  mounted() {
    this.uid = window.localStorage.getItem('uid') || ''
    this.getNav()

    // this.getrecommendList()
  },
  methods: {
    onReachBottom() {
      return [{ id: 1 }, { id: 2 }, { id: 3 }]
    },
    mescrollInit(data) {
      this.mescroll = data
    },
    // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getListDataFromNet(
        this.pdType,
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.commendList = []
          // 把请求到的数据添加到列表
          this.commendList = this.commendList.concat(arr)
          this.waterData = arr
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },

    getListDataFromNet(
      pdType,
      pageNum,
      pageSize,
      successCallback,
      errorCallback
    ) {
      const opt = {
        // categoryId: "8783,8797",
        categoryId: '8792,8805',
        page: pageNum,
        pageSize: pageSize
      }
      getrecommendList(opt).then((res) => {
        if (Number(res.code) === 200) {
          successCallback(res.data)
        }
      })
    },
    getNav() {
      const opt = {
        categoryId: '8792,8805',
        channelId: 3,
        uid: this.uid
      }
      getNav(opt).then((res) => {
        const _this = this
        _this.Banner = res.data.banner
        _this.category = res.data.category
        _this.activity = res.data.activity
      })
    }
    // getrecommendList () {
    //   let _this = this
    //   let opt = {
    //     categoryId: '8792,8805',
    //     page: _this.page,
    //     pageSize: _this.pageSize
    //   }
    //   getrecommendList(opt).then(res => {
    //     this.commendList = res.data
    //   })
    // }
  }
}
</script>
<style lang="less" scoped>
body {
  //background: #f5f7fa;

  padding-bottom: 100px;
}

.fresh_box {
  width: 100%;
  height: 100vh;
  background: #f6f6f6;
}

.headdiv {
  padding: 0 12px;
}

.stickyBox {
  height: 82px;

  background: #fff;
}

.nav-fixed {
  z-index: 9999;
  position: fixed;
  top: 44px;
  left: 0;
  width: 100%;
  background: #fff;
}

.recommended_item {
  // width: 171px;
  width: 100%;
  padding: 10px 16px;
  background: #fff;
  border-radius: 9px;
  margin-bottom: 9px;

  .tit {
    color: #191919;
    font-size: 14px;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; /*要显示的行数*/
    -webkit-box-orient: vertical;
    margin: 10px 0 5px 0;
    line-height: 20px;
    font-weight: bold;
  }

  .desc {
    color: #999;
    font-weight: bold;
    font-size: 12px;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1; /*要显示的行数*/
    -webkit-box-orient: vertical;
    height: 12px;
  }

  .money {
    font-size: 17px;
    color: #c82519;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 4px;

    span {
      text-decoration: line-through;
      color: #bbbbbb;
      font-size: 12px;
      margin-left: 2px;
      line-height: 23px;
    }

    em {
      font-size: 12px;
      font-style: normal;
    }
  }
}

</style>
